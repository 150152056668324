<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
	[allowColumnReordering]="true" [columnAutoWidth]="true" [filterSyncEnabled]="true"
	[remoteOperations]="{ groupPaging: true }" [dateSerializationFormat]="'yyyy-MM-dd'" [columnAutoWidth]="true"
	[filterValue]="filterValue" (onRowClick)="doPricelistApproverDetails($event);" [width]="'100%'"
	(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true"> </dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>

	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>


	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="ixcode" caption="Approval Code" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="title" caption="Workflow" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="type" caption="Type" dataType="string" [visible]="false"></dxi-column>
	<dxi-column dataField="doctype" caption="Doc. Type" dataType="string" [visible]="false">
	</dxi-column>
	<dxi-column dataField="pricelistno" caption="Price List No" dataType="string"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="pricelistname" caption="Price List Name" dataType="string"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="startdate" caption="Price List Start Date" dataType="date" format="yyyy-MM-dd"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="subtype" caption="Workflow Type" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateSubType">
		<dxo-header-filter [dataSource]="listOfWorkflowTypesHeaderFilters"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateSubType'">
			{{ element.data.subtype || '' | filterFromList: listOfWorkflowTypes: 'value' : 'name'}}
		</div>
	</dxi-column>
	<dxi-column dataField="approval_status" caption="Approval Status" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfPricelistStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div
				[ngClass]="element.data.approval_status || '' | filterFromList:listOfPricelistStatuses : 'value' : 'classname'">
				{{ element.data.approval_status || '' | filterFromList:listOfPricelistStatuses:'value':'text'}}
			</div>
		</div>
	</dxi-column>
	<dxi-column dataField="method" caption="Method" dataType="string" [allowHeaderFiltering]="false" [visible]="false">
	</dxi-column>

</dx-data-grid>