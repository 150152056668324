<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
	[allowColumnReordering]="true" [filterSyncEnabled]="true" [remoteOperations]="{ groupPaging: true }"
	[dateSerializationFormat]="'yyyy-MM-dd'" [filterValue]="filterValue" (onRowClick)="doViewSettlementReport($event);"
	[width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true"> </dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>

	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>

	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="type" caption="Type" dataType="string" [visible]="false"
		[showInColumnChooser]="false"></dxi-column>
	<dxi-column dataField="doctype" caption="Doc. Type" dataType="string" [visible]="false"
		[showInColumnChooser]="false">
	</dxi-column>

	<dxi-column dataField="ixcode" caption="Approval Code" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="title" caption="Workflow" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="settlement_no" caption="Settlement No." dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column dataField="contractName" caption="Contract Name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Open Settlement Start Date" dataType="date"
		format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementStartDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateOpenSettlementStartDate'">
			{{ element.data.settlementPeriodStart || "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="Open Settlement End Date" dataType="date"
		format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementEndDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateOpenSettlementEndDate'">
			{{ element.data.settlementPeriodEnd || '' }}
		</div>
	</dxi-column>

	<dxi-column dataField="settlementValue" caption="Settlement Amount" dataType="number" [allowHeaderFiltering]="false"
		[allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="approval_user_list" alignment="center" caption="Approver" dataType="object"
		format="yyyy-MM-dd" cellTemplate="cellTemplateOpenApproverBy" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateOpenApproverBy'">
			<span *ngIf="element.data.approval_user_list && element.data.approval_user_list.length > 0">
				{{element.data.approval_user_list[0] || '' | filterFromList:listOfRecipientsUsers : 'sub_account_id'
				:'name'}}
				<span
					*ngIf="element.data.approval_user_list[0] && (element.data.approval_user_list.length === 2 || element.data.approval_user_list.length >= 3)"
					id="approverToolTip_{{element.data.guid}}">
					+ {{element.data.approval_user_list.length-1}}
					{{ element.data.approval_user_list.length === 2 ? 'Other' : element.data.approval_user_list.length >= 3 ?
					'Others' : ''}}
				</span>
				<dx-tooltip target="#approverToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="right">
					<div *dxTemplate="let d = d; of: 'content'">
						<div class="tooltip-wrapper">
							<div class="greenTipout">
								<ol class="tipList">
									<li *ngFor="let approver of element.data.approval_user_list; let p = index;">
										<a>
											<span>
												{{approver || '' | filterFromList:listOfRecipientsUsers :
												'sub_account_id' : 'name'}}
											</span>
										</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</dx-tooltip>
			</span>
		</div>
	</dxi-column>

	<dxi-column dataField="level" alignment="center" caption="Workflow Level" dataType="number"
		cellTemplate="cellTemplateLevel" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateLevel'">
			{{'Level '+element.data.level || 0}}
		</div>
	</dxi-column>

	<dxi-column dataField="approval_status" caption="Approval Status" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfApprovalStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div
				[ngClass]="element.data.approval_status || '' | filterFromList:listOfApprovalStatuses : 'value' : 'classname'">
				{{ element.data.approval_status || '' | filterFromList:listOfApprovalStatuses:'value':'text'}}
			</div>
		</div>
	</dxi-column>

</dx-data-grid>