import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CommissionApprovalStatusValEnum, ContractTypeValEnum, RebateApprovalStatusValEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Observable, Subject, Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Router, ActivatedRoute } from '@angular/router';
import { FilterFromListPipe } from '@app/pipes/filter-from-list.pipe';
import * as _ from 'underscore';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { LoaderService } from '@app/services/loaderservices/loader.service';

@Component({
	selector: 'app-commission-settlements-approvals-listing',
	templateUrl: './commission-settlements-approvals-listing.component.html',
	styleUrls: ['./commission-settlements-approvals-listing.component.css'],
	providers: [FilterFromListPipe]
})
export class CommissionSettlementsApprovalsListingComponent implements OnInit, OnDestroy {
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public approvalsDataSource: any = {};
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	private dataInReqSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public isViewApprovalPopUp: boolean = false;

	private isCellClickDetail: boolean = false;
	@ViewChild("endUserChargeBacDocumentDataGridContainer", {}) endUserChargeBacDocumentDataGridContainer: DxDataGridComponent;
	public filterValue: any[] = [];
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public storedFiltersValue: any;
	public stateStorageKey: string;
	public mainSectionStorageKey: string;
	public isEnableRebateSettlementApprovalWorkflow: boolean;
	public listOfApprovalStatuses: any[] = [];
	public listOfWorkflowTypes: any[] = [];
	public listOfWorkflowTypesHeaderFilters: any[] = [];
	private getWorkflowTypeSbsn: Subscription;
	public listOfSettlementPeriods: any[] = [];
	public listOfRebateFor: any[] = [];
	public listOfRebateType: any[] = [];
	public listOfRecipientsUsers: any[] = [];
	private getListOfRecipientsUserSbsn: Subscription;
	public decimalPointForCurrency: number;
	private getContractDetailsSbsn: Subscription;
	public listOfActions = [
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}
	];
	private getFeatureCustomFieldsLstSbn: Subscription;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _FilterFromListPipe: FilterFromListPipe,
		private _CommissionService: CommissionService) {
		this.doLoadApproveNotificationState = this.doLoadApproveNotificationState.bind(this);
		this.doSaveApproveNotificationState = this.doSaveApproveNotificationState.bind(this);
	}

	ngOnInit(): void {
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getWorkflowTypeSbsn) {
			this.getWorkflowTypeSbsn.unsubscribe();
		}
		if (this.getListOfRecipientsUserSbsn) {
			this.getListOfRecipientsUserSbsn.unsubscribe();
		}
		if (this.getContractDetailsSbsn) {
			this.getContractDetailsSbsn.unsubscribe();
		}
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
	}

	private preInIt() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.listOfSettlementPeriods = this._CommissionService.getListOfSettlementPeriods;
		let isEnableRebateSettlementApprovalWorkflow = false;
		try {
			isEnableRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnableRebateSettlementApprovalWorkflow = false;
		}
		this.isEnableRebateSettlementApprovalWorkflow = isEnableRebateSettlementApprovalWorkflow;
		this.stateStorageKey = ServerSections.REBATE_SETTLEMENTS_APPROVE_NOTIFICATIONS;
		this.mainSectionStorageKey = ServerSections.NOTIFICATIONS;
		this.listOfApprovalStatuses = this._CommissionService.getListOfApprovalStatuses;
		this.fetchListOfWorkflowTypes();
		this.storedFiltersValue = [
			{ dataType: 'string', dataField: 'approval_status', searchMode: '=', filterValues: [CommissionApprovalStatusValEnum.PENDING_APPROVAL] },
		];
		this.applyStoredFilter();
		this.getApprovalList();
		this.getListOfRecipientsUsers();
		this.doGetFeatureCustomFields();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
			this.updateStatesClearBtn();
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.filterText = '';
						this.isDisabledClearBtn = true;
						this.updateStatesClearBtn();
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);

		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {

	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Commission_Settlement_Approval_List.xlsx';
		worksheet = workbook.addWorksheet('Commission Settlement Approval List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'subtype') {
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfWorkflowTypes, 'value', 'name');
					}
					if (gridCell.column.dataField === 'approval_status') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfApprovalStatuses, 'value', 'text');
						colorName = this._FilterFromListPipe.transform(gridCell.value, this.listOfApprovalStatuses, 'value', 'color_code');
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						if (sf.filterValues) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
						}
					}
				});
			}, 75);
		}

	}

	// Method used to Fetch list of Task types
	private fetchListOfWorkflowTypes() {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.WORKFLOWTYPES);
		if (this.getWorkflowTypeSbsn) {
			this.getWorkflowTypeSbsn.unsubscribe();
		}
		this.listOfWorkflowTypes = [];
		this.getWorkflowTypeSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					this.listOfWorkflowTypes = response.data || [];
					this.listOfWorkflowTypesHeaderFilters = this.listOfWorkflowTypes.map(wft => {
						return { value: wft.value, text: wft.name };
					});
				}
			},
			error: (error) => {
			}
		});
	}

	private getApprovalList() {
		const viewFields = [];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.APPROVALS,
			type: 'commissioncontracts',
			// viewFields: JSON.stringify(viewFields),
		};
		this.approvalsDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});

		// this.approvalsDataSource = [{
		// 	"guid": "11397d3e26288b553afb8a84b9bae7bb",
		// 	"title": "Commission WF",
		// 	"entity": "commissionscontracts",
		// 	"subtype": "commission_settlement_approval_workflow",
		// 	"doctype": "commissionscontracts",
		// 	"description": null,
		// 	"workflow_guid": "86e0aae3205d4a0b9811590c11a5a3be",
		// 	"settlement_guid": "cc9ebd3f8d324d7cb744e0f555e05964",
		// 	"baseContractCode": "b6523d69ed6b404f852a8c933afb9aff",
		// 	"settlement_no": "IXCSP1175",
		// 	"contractName": "JL Push test",
		// 	"startDate": "2024-01-01",
		// 	"endDate": "2025-02-11",
		// 	"settlementPeriodStart": "2024-11-02",
		// 	"settlementPeriodEnd": "2024-12-31",
		// 	"settlementPeriod": "yearly",
		// 	"calculationOnBasis": "revenue",
		// 	"customer_item_match": "exclusive",
		// 	"settlementValue": 0,
		// 	"approval_status": "pending_approval",
		// 	"created_by_guid": "fd0d63f6a36e4acc9cdea01f5a1fbd07",
		// 	"approval_user_list": [
		// 		"fd0d63f6a36e4acc9cdea01f5a1fbd07"
		// 	],
		// 	"level": 1,
		// 	"created_date": "2025-01-21 07:47:40",
		// 	"modified_date": "2025-01-21 07:47:40",
		// 	"ixcode": "IXAPR1",
		// 	"issynctoerp": true
		// }];

	}

	private getListOfRecipientsUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		const selectedListOfUserTypes = listOfUserTypes.filter(userType => userType.can_receive_incentives === true);

		if (selectedListOfUserTypes && selectedListOfUserTypes.length > 0) {
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'role', 'role_name', 'status', 'notification', 'email'];
			const listOfRoles = _.pluck(listOfUserTypes, 'user_type');
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
			reqFormData.append('entity', ServerEntity.SUBUSERS);
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfRecipientsUserSbsn) {
				this.getListOfRecipientsUserSbsn.unsubscribe();
			}
			this.listOfRecipientsUsers = [];
			this.getListOfRecipientsUserSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						const listOfUsers = response.data || [];
						listOfUsers.map(user => {
							let strValue = user.first_name ? user.first_name : '';
							strValue += user.first_name && user.last_name ? ' ' + user.last_name : '';
							user.name = strValue;
							this.listOfRecipientsUsers.push(user);
						});
					}
				},
				error: (error) => {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		// this.isCellClickDetail = false;
		// if (e.column.dataField === "claimedStatus") {
		// 	this.isCellClickDetail = true;
		// 	return;
		// }
	}

	public doViewSettlementReport(event: any) {
		const rowData = event.data;
		// const rowDetails = {
		// 	approval_guid: rowData.guid,
		// 	approval_status: rowData.approval_status,
		// 	baseContractCode: rowData.baseContractCode,
		// 	guid: rowData.settlement_guid,
		// 	settlement_no: rowData.settlement_no,
		// 	contractName: rowData.contractName,
		// };

		this.doRedirectToReport(rowData);
	}

	private doRedirectToReport(rowData: any) {
		const search = [{ 'guid': rowData.baseContractCode }];
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.COMMISSIONCONTRACTS);
		reqFormData.append('search', JSON.stringify(search));

		if (this.getContractDetailsSbsn) {
			this.getContractDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getContractDetailsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					const commissionDetails = response.data[0];

					const search = ["guid", "=", rowData.settlement_guid];
					const reqFormData = new FormData();
					reqFormData.append('usr', this._LoginService.loginUser.user);
					reqFormData.append('token', this._LoginService.loginUser.token);
					reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
					reqFormData.append('entity', ServerEntity.CLOSESETTLEMENTS);
					reqFormData.append('contracttype', 'commissioncontracts');
					reqFormData.append('baseContractCode', rowData.baseContractCode);
					reqFormData.append('filter', JSON.stringify(search));

					if (this.getContractDetailsSbsn) {
						this.getContractDetailsSbsn.unsubscribe();
					}
					this._LoaderService.show();
					this.getContractDetailsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
						next: (response) => {
							this._LoaderService.hide();
							if (response && response.flag) {
								const invoiceDetails = response.data[0];
								const isMordenViewRpt: boolean = this._AppCommonSrvc.checkCommissionReportMordenView(ContractTypeValEnum.COMMISSIONCONTRACTS);
								if (isMordenViewRpt) {
									let commissionSettlemntRptModel: CommissionSettlemntRptModel = new CommissionSettlemntRptModel();
									commissionSettlemntRptModel.contractGuid = invoiceDetails.guid;
									commissionSettlemntRptModel.baseContractGuid = commissionDetails.guid;
									commissionSettlemntRptModel.ixcode = invoiceDetails.ixcode;
									commissionSettlemntRptModel.contractName = invoiceDetails.contractName;
									commissionSettlemntRptModel.currency_code = commissionDetails.currency_code;
									commissionSettlemntRptModel.calculate_commission_by = commissionDetails.calculate_commission_by;
									commissionSettlemntRptModel.salesRep = invoiceDetails.subusername;
									commissionSettlemntRptModel.calculationOnBasis = invoiceDetails.calculationOnBasis;
									commissionSettlemntRptModel.settlementPeriodStartDate = invoiceDetails.settlementPeriodStart;
									commissionSettlemntRptModel.settlementPeriodEndDate = invoiceDetails.settlementPeriodEnd;
									commissionSettlemntRptModel.created_date = invoiceDetails.created_date;
									commissionSettlemntRptModel.apInvoiceCreatedInERP = invoiceDetails.apInvoiceCreatedInERP;
									commissionSettlemntRptModel.remark = invoiceDetails.errorMessage;
									commissionSettlemntRptModel.totalCommissionGenerated = invoiceDetails.totalCommissionGenerated;
									commissionSettlemntRptModel.totalCommissionPayable = invoiceDetails.settlementValue;
									commissionSettlemntRptModel.subusername = invoiceDetails.subusername;
									commissionSettlemntRptModel.apInvoiceNumber = invoiceDetails.apInvoiceNumber;
									commissionSettlemntRptModel.currencyFormat = this._AppCommonSrvc.currencyFormat(commissionDetails.currency_code);
									commissionSettlemntRptModel.approval_status = invoiceDetails.approval_status || '';
									this._CommissionService.setEditCommissionGuid(commissionDetails.guid);
									this._CommissionService.setCommissionRptDetails(commissionSettlemntRptModel);
									this._CommissionService.setApprovalDetails(rowData);
									this._Router.navigate(['../commission-modern-report'], { relativeTo: this._ActivatedRoute });
								} else {
									this._CommissionService.setInvoiceDetails(invoiceDetails);
									this._CommissionService.setCommissionContractDetails(commissionDetails);
									this._CommissionService.setApprovalDetails(rowData);
									this._Router.navigate(['../commission-report'], { relativeTo: this._ActivatedRoute });
								}
							}
						},
						error: (error) => {
							this._LoaderService.hide();
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					});
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#region load state and save state
	public doLoadApproveNotificationState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				//this.filterValue = newOrExistingConfigClone.configs[configIndex].state_persistent['filterValue'];
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveApproveNotificationState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
	//#endregion

	// Method used to get Custom fileds for invoice
	private doGetFeatureCustomFields() {
		const feature_guid = this._CommissionService.getFeatureGuid() || '';
		if (!feature_guid) {
			return false;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formData.append('search', JSON.stringify([{ feature_guid: feature_guid }]));
		formData.append('is_dropdown', 'true');
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		this.getFeatureCustomFieldsLstSbn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			if (response) {
				if (response.flag) {
					this._CommissionService.setlistOfFeatureCustomFields(response.data || []);
				}
			}
		}, error => {

		});

	}
}
