<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
	[allowColumnReordering]="true" [columnAutoWidth]="true" [filterSyncEnabled]="true"
	[remoteOperations]="{ groupPaging: true }" [dateSerializationFormat]="'yyyy-MM-dd'" [columnAutoWidth]="true"
	[filterValue]="filterValue" (onRowClick)="doViewClaimApprovalsPopup($event.data);" [width]="'100%'"
	(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true"> </dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>

	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>


	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="claim_id" caption="Claim Id" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateClaimId">
		<div *dxTemplate="let element of 'cellTemplateClaimId'">
			{{ element.data.claim_id}}
		</div>
	</dxi-column>

	<dxi-column dataField="title" caption="Workflow Title" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="subtype" caption="Workflow Type" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateSubType">
		<dxo-header-filter [dataSource]="listOfWorkflowTypesHeaderFilters"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateSubType'">
			{{ element.data.subtype || '' | filterFromList: listOfWorkflowTypes: 'value' : 'name'}}
		</div>
	</dxi-column>

	<dxi-column dataField="type" caption="Type" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateType" [visible]="false" [showInColumnChooser]="false">
		<div *dxTemplate="let element of 'cellTemplateType'">
			{{ element.data.type}}
		</div>
	</dxi-column>

	<dxi-column dataField="promotionName" caption="Promotion Name" dataType="string" [allowHeaderFiltering]="false"
		[allowFiltering]="true">
	</dxi-column>

	<dxi-column dataField="promotionCode" caption="Promotion Code" dataType="string" [allowHeaderFiltering]="false"
		[allowFiltering]="true">
	</dxi-column>

	<dxi-column dataField="created_date" caption="Document Date" dataType="date" format="yyyy-MM-dd"
		[allowHeaderFiltering]="false" cellTemplate="cellTemplateDocDate">
		<div *dxTemplate="let element of 'cellTemplateDocDate'">
			{{ element.data.created_date || "" | customDateFormatPipe:'usadatewithdash'}}
		</div>
	</dxi-column>
	<dxi-column dataField="claim_date" caption="Claim Date" dataType="date" format="yyyy-MM-dd"
		[allowHeaderFiltering]="false" cellTemplate="cellTemplateClaimDate">
		<div *dxTemplate="let element of 'cellTemplateClaimDate'">
			{{ element.data.claim_date || "" | customDateFormatPipe:'usadatewithdash' }}
		</div>
	</dxi-column>

	<dxi-column dataField="claim_amount" caption="Approved Amount" dataType="number" [allowHeaderFiltering]="false"
		[allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="approval_status" caption="Approval Status" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfApprovalStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div
				[ngClass]="element.data.approval_status || '' | filterFromList:listOfApprovalStatuses : 'value' : 'classname'">
				{{ element.data.approval_status || '' | filterFromList:listOfApprovalStatuses:'value':'text'}}
			</div>
		</div>
	</dxi-column>

	<dxi-column type="buttons" caption="Action" [fixed]="true" fixedPosition="right" [allowFiltering]="false"
		[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center" [allowResizing]="false" [minWidth]="80"
		[width]="90" [allowExporting]="false" cellTemplate="cellTemplateAction">
		<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
			<a (click)="doViewClaimApprovalsPopup(element.data)">
				<span class="icon-view icon-view_action-col"></span>
			</a>
		</div>
	</dxi-column>

</dx-data-grid>

<app-trade-promotion-view-claim-details-dialog *ngIf="isShowViewClaimDetailsPopup"
	[claimDetailsProps]="viewClaimDetailsProps" [approvalDetailsProps]="approvalDetailsProps"
	(closeViewClaimDetailsPopup)="doCloseViewClaimDetailsPopup($event)"></app-trade-promotion-view-claim-details-dialog>